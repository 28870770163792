<div
  class="two-cols"
  [formGroup]="profileForm"
  [ngClass]="{ disabled: enableDisabled }"
>
  <div class="left">
    <table>
      <!-- Äußeres -->
      <tr>
        <h4 class="no-margin-top">Basics</h4>
      </tr>
      <!-- Geschlecht -->
      <tr [ngClass]="{ changed: isChanged('geschlecht') }">
        <td>
          Geschlecht
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('geschlecht')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="geschlecht"
            id="geschlecht"
            formControlName="geschlecht"
          >
            <option value=""></option>
            <option *ngFor="let g of geschlecht" [ngValue]="g.value">
              {{ g.label }}
            </option>
          </select>
          <span class="undo" (click)="reset('geschlecht')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('geschlecht')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('geschlecht')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Name -->
      <tr
        [ngClass]="{ changed: isChanged('vorname') || isChanged('nachname') }"
      >
        <td>
          Name
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('vorname') || isChanged('nachname')"
          ></app-svgs>
        </td>
        <td>
          <div class="two-cols" *ngIf="!profileForm.disabled">
            <div class="left">
              <input
                type="text"
                name="vorname"
                id="vorname"
                formControlName="vorname"
                autocomplete="given-name"
              />
            </div>
            <div class="right">
              <input
                type="text"
                name="nachname"
                id="nachname"
                formControlName="nachname"
                autocomplete="family-name"
              />
            </div>
          </div>
          <div *ngIf="profileForm.disabled">
            {{ profileForm.value["vorname"] }}
            {{ profileForm.value["nachname"] }}
          </div>
          <span class="undo" (click)="reset('vorname'); reset('nachname')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('vorname') || isChanged('nachname')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              (profileForm.get('vorname')?.invalid ||
                profileForm.get('nachname')?.invalid) &&
              submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <!-- Weitere Vornamen -->
      <tr [ngClass]="{ changed: isChanged('weitere_vornamen') }">
        <td>
          Weitere Vornamen
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('weitere_vornamen')"
          ></app-svgs>
        </td>
        <td>
          <input
            type="text"
            name="weitere_vornamen"
            id="weitere_vornamen"
            formControlName="weitere_vornamen"
          />
          <span class="undo" (click)="reset('weitere_vornamen')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('weitere_vornamen')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('weitere_vornamen')?.invalid; submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <!-- Geboren am -->
      <tr [ngClass]="{ changed: isChanged('geburtsdatum') }">
        <td>
          Geboren am
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('geburtsdatum')"
          ></app-svgs>
        </td>
        <td>
          <app-dob-picker
            *ngIf="profileForm && profileForm.controls['geburtsdatum']"
            [formCntrl]="profileForm.controls['geburtsdatum']"
          ></app-dob-picker>

          <span class="undo" (click)="reset('geburtsdatum')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('geburtsdatum')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('geburtsdatum')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <!-- Staatsangehörigkeit -->
      <tr [ngClass]="{ changed: isChanged('staatsangehoerigkeit_arr') }">
        <td>
          Staatsangehörigkeit
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('staatsangehoerigkeit_arr')"
          ></app-svgs>
        </td>
        <td *ngIf="!profileForm.disabled">
          <div style="height: 36px; display: block">
            <ng-select
              [multiple]="true"
              name="staatsangehoerigkeit_arr"
              id="staatsangehoerigkeit_arr"
              formControlName="staatsangehoerigkeit_arr"
            >
              <ng-option value=""></ng-option>
              <ng-option
                [value]="sa"
                *ngFor="let sa of getStaatsstaatsangehoearigkeit"
              >
                {{ sa }}
              </ng-option>
            </ng-select>
          </div>

          <span class="undo" (click)="reset('staatsangehoerigkeit_arr')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('staatsangehoerigkeit_arr')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('staatsangehoerigkeit_arr')?.invalid &&
              submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
        <td *ngIf="profileForm.disabled">
          {{ (profileForm.value.staatsangehoerigkeit_arr || []).join(", ") }}
        </td>
      </tr>

      <!-- Geburtsort -->
      <tr [ngClass]="{ changed: isChanged('geburtsstadt') }">
        <td>
          Geburtsort
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('geburtsstadt')"
          ></app-svgs>
        </td>
        <td>
          <input
            type="text"
            name="geburtsstadt"
            id="geburtsstadt"
            formControlName="geburtsstadt"
          />

          <span class="undo" (click)="reset('geburtsstadt')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('geburtsstadt')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('geburtsstadt')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Geburtsland -->
      <tr [ngClass]="{ changed: isChanged('geburtsland') }">
        <td>
          Geburtsland
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('geburtsland')"
          ></app-svgs>
        </td>
        <td *ngIf="!profileForm.disabled">
          <ng-select
            [items]="getStaaten"
            bindLabel="label"
            bindValue="id"
            formControlName="geburtsland"
            autocomplete="country"
          >
          </ng-select>

          <span class="undo" (click)="reset('geburtsland')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('geburtsland')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('geburtsland')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
        <td *ngIf="profileForm.disabled">
          {{ getStaat(profileForm.value.geburtsland || "") }}
        </td>
      </tr>

      <!-- Ausweisnummer -->
      <tr [ngClass]="{ changed: isChanged('ausweisnummer') }">
        <td>
          Ausweisnummer
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('ausweisnummer')"
          ></app-svgs>
        </td>
        <td>
          <input
            type="text"
            name="ausweisnummer"
            id="ausweisnummer"
            formControlName="ausweisnummer"
          />

          <span class="undo" (click)="reset('ausweisnummer')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('ausweisnummer')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('ausweisnummer')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <!-- Kontakt -->
      <tr>
        <h4>Kontakt</h4>
      </tr>
      <!-- E-Mail -->
      <tr *ngIf="profileForm.controls.email.disabled">
        <td>E-Mail</td>
        <td>
          {{ user.email }}
        </td>
      </tr>
      <tr
        [ngClass]="{ changed: isChanged('ausweisnummer') }"
        *ngIf="!profileForm.controls.email.disabled"
      >
        <td>
          E-Mail
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('email')"
          ></app-svgs>
        </td>
        <td>
          <input type="email" name="email" id="email" formControlName="email" />

          <span class="undo" (click)="reset('email')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('email')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('email')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Telefon -->
      <tr [ngClass]="{ changed: isChanged('tel') }">
        <td>
          Telefon
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('tel')"
          ></app-svgs>
        </td>
        <td>
          <input
            placeholder="+49 176 123456"
            type="text"
            name="tel"
            id="tel"
            formControlName="tel"
            autocomplete="tel"
          />
          <span class="undo" (click)="reset('tel')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('tel')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('tel')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Strasse -->
      <tr [ngClass]="{ changed: isChanged('strasse') }">
        <td>
          Strasse
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('strasse')"
          ></app-svgs>
        </td>
        <td>
          <div class="two-cols">
            <input
              type="text"
              name="strasse"
              id="strasse"
              formControlName="strasse"
              autocomplete="address-line1"
            />
          </div>
          <span class="undo" (click)="reset('strasse')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('strasse')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('strasse')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Nr. -->
      <tr [ngClass]="{ changed: isChanged('hausnr') }">
        <td>
          Nr.
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('hausnr')"
          ></app-svgs>
        </td>
        <td>
          <div class="two-cols">
            <input
              type="text"
              name="hausnr"
              id="hausnr"
              formControlName="hausnr"
              autocomplete="address-line1"
            />
          </div>
          <span class="undo" (click)="reset('hausnr')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('hausnr')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('hausnr')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- PLZ, Ort -->
      <tr [ngClass]="{ changed: isChanged('plz') }">
        <td>
          PLZ
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('plz')"
          ></app-svgs>
        </td>
        <td>
          <div class="two-cols">
            <input
              type="text"
              min="0"
              name="plz"
              id="plz"
              formControlName="plz"
              autocomplete="postal-code"
            />
          </div>
          <span class="undo" (click)="reset('plz')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('plz')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('plz')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Ort -->
      <tr [ngClass]="{ changed: isChanged('ort') }">
        <td>
          Ort
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('ort')"
          ></app-svgs>
        </td>
        <td>
          <div class="two-cols">
            <input
              type="text"
              name="ort"
              id="ort"
              formControlName="ort"
              autocomplete="address-level2"
            />
          </div>
          <span class="undo" (click)="reset('ort')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('ort')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('ort')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Wohnland -->
      <tr [ngClass]="{ changed: isChanged('wohnland') }">
        <td>
          Wohnland
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('wohnland')"
          ></app-svgs>
        </td>
        <td *ngIf="!profileForm.disabled">
          <ng-select
            class="hide-delete-button"
            [items]="staaten"
            bindLabel="label"
            bindValue="id"
            formControlName="wohnland"
            autocomplete="country"
          >
          </ng-select>
          <span class="undo" (click)="reset('wohnland')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('wohnland')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('wohnland')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
        <td *ngIf="profileForm.disabled">
          {{ getStaat(profileForm.value.wohnland) || "" }}
        </td>
      </tr>
      <!-- Kontakt -->
      <tr>
        <h4>Bankverbindung</h4>
      </tr>
      <!-- IBAN -->
      <tr [ngClass]="{ changed: isChanged('iban') }">
        <td>
          IBAN
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('iban')"
          ></app-svgs>
        </td>
        <td>
          <input type="text" name="iban" id="iban" formControlName="iban" />

          <span class="undo" (click)="reset('iban')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('iban')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('iban')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- BIC -->
      <tr [ngClass]="{ changed: isChanged('bic') }">
        <td>
          BIC
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('bic')"
          ></app-svgs>
        </td>
        <td>
          <input type="text" name="bic" id="bic" formControlName="bic" />

          <span class="undo" (click)="reset('bic')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('bic')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('bic')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="right">
    <!-- Kontakt -->
    <table>
      <tr>
        <h4 class="no-margin-top flex flex-center-vertical gap-7">
          <span>Status</span>
          <span
            class="badge success"
            *ngIf="showStatus"
            [ngClass]="{ rejected: hasValidStatusErrors.length }"
            [title]="hasValidStatusErrors.join('\n')"
            ><app-svgs
              [type]="!hasValidStatusErrors.length ? 'checkmark' : 'close'"
              color="white"
            ></app-svgs
          ></span>
        </h4>
      </tr>

      <!-- Ausweisdokument -->
      <tr *ngIf="user?.profile?.ausweis_vorne && user?.profile?.ausweis_hinten">
        <td>Ausweisdokument</td>
      </tr>
      <tr *ngIf="user?.profile?.ausweis_vorne && user?.profile?.ausweis_hinten">
        <div style="width: 100%">
          <app-fileupload
            [allowPdf]="false"
            [onlyPreview]="true"
            [uploadFiles]="[
              (user?.profile?.ausweis_vorne)![0],
              (user?.profile?.ausweis_hinten)![0]
            ]"
          >
          </app-fileupload>
        </div>
      </tr>

      <!-- Beschäftigung -->
      <!-- TODO: In Komponente packen und editable machen... aber nur wenn unbedingt nötig -->
      <tr>
        <app-arbeitsbeginn-status
          [persForm]="profileForm"
          [onlyPreview]="!arbeitskreafteEdit"
          [isCustomerFacing]="false"
          [modalDesign]="true"
        ></app-arbeitsbeginn-status>
        <!-- Laufendes Jahr -->
      </tr>

      <tr>
        <td>KfB Tage laufendes Jahr ({{ currentYear }}):</td>
        <td>
          <span *ngIf="laufendesJahrKfB">extern: {{ laufendesJahrKfB }}</span
          ><span *ngIf="laufendesJahrKfB && laufendesJahrKfBGenerated">, </span
          ><span *ngIf="laufendesJahrKfBGenerated">
            bei Topteam GmbH: {{ laufendesJahrKfBGenerated }}</span
          >
        </td>
      </tr>

      <!-- Sozialversicherungsnr. -->
      <tr>
        <h4>Rechtliches</h4>
      </tr>
      <tr
        [ngClass]="{ changed: isChanged('sozialversicherungsnummer') }"
        *ngIf="
          !profileForm.controls['sozialversicherungsnummer']?.disabled ||
          profileForm.disabled
        "
      >
        <td>
          Sozialversicherungsnr.
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('sozialversicherungsnummer')"
          ></app-svgs>
        </td>
        <td>
          <input
            type="text"
            name="sozialversicherungsnummer"
            id="sozialversicherungsnummer"
            formControlName="sozialversicherungsnummer"
          />

          <span class="undo" (click)="reset('sozialversicherungsnummer')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('sozialversicherungsnummer')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('sozialversicherungsnummer')?.invalid &&
              submitAttempt
            "
            class="alert"
          >
            Bitte gebe eine gültige
            {{
              profileForm.value.wohnland == "CH"
                ? "AHV-Nummer"
                : "Sozialversicherungsnummer"
            }}
            für Ihr Wohnland an. Oder leer lassen.
          </div>
        </td>
      </tr>
      <!-- Steueridentifikationsnr. -->
      <tr [ngClass]="{ changed: isChanged('steueridentifikationsnummer') }">
        <td>
          {{
            profileForm.value.wohnland == "CH"
              ? "AHV-Nummer"
              : "Steueridentifikationsnr"
          }}
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('steueridentifikationsnummer')"
          ></app-svgs>
        </td>
        <td>
          <input
            type="text"
            name="steueridentifikationsnummer"
            id="steueridentifikationsnummer"
            formControlName="steueridentifikationsnummer"
            [placeholder]="
              profileForm.value.wohnland == 'CH'
                ? '756.1234.5678.95'
                : profileForm.value.wohnland == 'DE'
                ? '12345678000'
                : profileForm.value.wohnland == 'AT'
                ? '03-999/9032'
                : ''
            "
          />

          <span class="undo" (click)="reset('steueridentifikationsnummer')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('steueridentifikationsnummer')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('steueridentifikationsnummer')?.invalid &&
              submitAttempt
            "
            class="alert"
          >
            Bitte gebe eine gültige
            {{
              profileForm.value.wohnland == "CH"
                ? "AHV-Nummer"
                : "Steueridentifikationsnummer"
            }}
            für Ihr Wohnland an. Oder leer lassen.
          </div>
        </td>
      </tr>
      <!-- Steuerklasse -->
      <tr [ngClass]="{ changed: isChanged('steuerklasse') }">
        <td>
          Steuerklasse
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('steuerklasse')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="steuerklasse"
            id="steuerklasse"
            formControlName="steuerklasse"
          >
            <option value=""></option>
            <option *ngFor="let sk of steuerKlassen" [ngValue]="sk">
              {{ sk }}
            </option>
          </select>

          <span class="undo" (click)="reset('steuerklasse')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('steuerklasse')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('steuerklasse')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Krankenkasse typ -->
      <tr>
        <td [ngClass]="{ changed: isChanged('krankenkasse_typ') }">
          Krankenkasse
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('krankenkasse_typ')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="krankenkasse_typ"
            id="krankenkasse_typ"
            formControlName="krankenkasse_typ"
          >
            <option ngValue=""></option>
            <option ngValue="gesetzlich">Gesetzlich</option>
            <option ngValue="privat">Privat</option>
            <option ngValue="andere">Andere</option>
          </select>

          <span class="undo" (click)="reset('krankenkasse_typ')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('krankenkasse_typ')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('krankenkasse_typ')?.invalid && submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Name Krankenkasse  -->
      <tr [ngClass]="{ changed: isChanged('krankenkasse_name') }">
        <td>
          Krankenkassenname
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('krankenkasse_name')"
          ></app-svgs>
        </td>
        <td *ngIf="!profileForm.disabled">
          <!-- *ngIf="getKK.length" -->
          <ng-select
            *ngIf="
              ['privat', 'gesetzlich'].includes(
                profileForm.value.krankenkasse_typ
              )
            "
            [items]="getKK"
            formControlName="krankenkasse_name"
            class="hide-delete-button"
          >
          </ng-select>
          <input
            type="text"
            formControlName="krankenkasse_name"
            id="krankenkasse_name"
            name="krankenkasse_name"
            [disabled]="!profileForm.value.krankenkasse_typ"
            *ngIf="['andere'].includes(profileForm.value.krankenkasse_typ)"
          />
          <span class="undo" (click)="reset('krankenkasse_name')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('krankenkasse_name')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('krankenkasse_name')?.invalid && submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
        <td *ngIf="profileForm.disabled">
          {{ profileForm.value.krankenkasse_name || "" }}
        </td>
      </tr>

      <!-- Familienstand  -->
      <tr>
        <td [ngClass]="{ changed: isChanged('familienstand') }">
          Familienstand
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('familienstand')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="familienstand"
            id="familienstand"
            formControlName="familienstand"
          >
            <option ngValue=""></option>
            <option ngValue="ledig">ledig</option>
            <option ngValue="verheiratet">verheiratet</option>
          </select>

          <span class="undo" (click)="reset('familienstand')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('familienstand')"
            ></app-svgs>
          </span>
          <div
            *ngIf="profileForm.get('familienstand')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Religion  -->
      <tr [ngClass]="{ changed: isChanged('religionszugehoerigkeit') }">
        <td>
          Religion
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('religionszugehoerigkeit')"
          ></app-svgs>
        </td>
        <td *ngIf="!profileForm.disabled">
          <ng-select
            [items]="getKonfessionen"
            formControlName="religionszugehoerigkeit"
            class="hide-delete-button"
            *ngIf="getKonfessionen.length"
          >
          </ng-select>
          <span class="undo" (click)="reset('religionszugehoerigkeit')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('religionszugehoerigkeit')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('religionszugehoerigkeit')?.invalid &&
              submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
        <td *ngIf="profileForm.disabled">
          {{ profileForm.value.religionszugehoerigkeit?.label || "" }}
        </td>
      </tr>

      <!-- Kinderfreibetrag  -->
      <tr [ngClass]="{ changed: isChanged('kinderfreibetrag') }">
        <td>
          Kinderfreibetrag
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('kinderfreibetrag')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="kinderfreibetrag"
            id="kinderfreibetrag"
            formControlName="kinderfreibetrag"
          >
            <option value=""></option>
            <option
              *ngFor="
                let g of [
                  { label: 'Nein', value: false },
                  { label: 'Ja', value: true }
                ]
              "
              [ngValue]="g.value"
            >
              {{ g.label }}
            </option>
          </select>

          <span class="undo" (click)="reset('kinderfreibetrag')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('kinderfreibetrag')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              profileForm.get('kinderfreibetrag')?.invalid && submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- <tr>
        <td>Kinderfreibetrag</td>
        <td>
          {{ profileForm.controls["kinderfreibetrag"].value ? "Ja" : "Nein" }}
        </td>
      </tr> -->
    </table>
  </div>
</div>

/* eslint-disable guard-for-in */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ZulagenLohnartMappingNeu } from "@ttc_types/lodas_mapping";
import {
  AllUsersResult,
  Application,
  ApplicationAction,
  ApplicationPermission,
  BeschaeftigungLaufendesJahr,
  Document,
  DokumentVorlage,
  EmploymentContract,
  Media,
  Profile,
  Projekt,
  RegistrationAction,
  SED,
  TeamTimeSlotDay,
  TimetrackerSlot,
  User,
  UserRole,
  sortDirection,
} from "@ttc_types/types";
import { Confirm, Loading, Notify } from "notiflix";
import { Subject, Subscription, firstValueFrom, timer } from "rxjs";
import { AuthService } from "src/app/shared/auth.service";
import * as XLSX from "xlsx";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { environment } from "../environments/environment.prod";
import { arbeitsBeginnStatusToArbeitsbeginnStatusProfileView } from "./mappers";
import { setDynamicHeightsAndWidthsOfWorksheet } from "./utils";
import { isRelevantForKFB } from "./shared/functions";

// import { Job } from './data'

@Injectable({
  providedIn: "root",
})
export class DataService {
  public debug = false;

  public keyboardOpen = false;

  public logoutTimer$?: Subscription;

  public minutesInactiveCount: number = 0;

  public minutesInactiveCountMax: number = 180;

  setLogoutTimer() {
    if (this.logoutTimer$) this.logoutTimer$.unsubscribe();
    this.logoutTimer$ = timer(60 * 1000, 60 * 1000) // Timer interval of 1 minute (60,000 milliseconds)
      .pipe()
      .subscribe(() => {
        if (!this.auth.isLoggedIn) {
          this.minutesInactiveCount = 0;
          return;
        }
        this.minutesInactiveCount += 1;
        if (
          this.minutesInactiveCount >= this.minutesInactiveCountMax - 10 &&
          this.minutesInactiveCount < this.minutesInactiveCountMax
        ) {
          const timeInTenMinutes = dayjs().add(10, "minute").format("HH:mm");
          Confirm.show(
            `Du wirst um ${timeInTenMinutes} automatisch ausgeloggt.`,
            "Möchtest du weiterhin eingeloggt bleiben?",
            "Nein",
            "Ja",
            async () => {
              this.auth.doLogout();
            },
            async () => {
              this.minutesInactiveCount = 0;
              await this.getRequest("/ping");
            },
            {
              messageMaxLength: 1000,
              titleMaxLength: 1000,
            }
          );
        } else if (this.minutesInactiveCount >= this.minutesInactiveCountMax) {
          this.auth.doLogout();
          try {
            document.getElementById("NXConfirmButtonOk")?.click();
          } catch (error) {
            console.error(error);
          }
        }
      });
  }

  // meine-projekte start
  public currentProjectPublished: boolean = false;

  public currentProjectTitle: string | null = null;

  public currentProjectInternalTitle: string | null = null;

  public currentProjectTitleSubmitAttempt: boolean = false;

  public currentProject: Projekt;

  public eventBus: Subject<string> = new Subject<string>();

  endpoint: string = environment.apiUrl;

  headers = new HttpHeaders().set("Content-Type", "application/json");

  public hideJetztBewerbenButton: boolean = false;

  // modalOpen: boolean = false;
  // modalTemplate:TemplateRef<any> | null = null;

  // meine-projekte end

  constructor(public auth: AuthService, public http: HttpClient) {
    this.debug = !environment.production;
    window.addEventListener("resize", () => {
      // if current/available height ratio is small enough, virtual keyboard is probably visible
      this.keyboardOpen =
        window.innerHeight / window.screen.availHeight < 0.6 &&
        window.innerWidth <= 900;
      console.log(`this.keyboardOpen`, this.keyboardOpen);
    });
    this.setLogoutTimer();
  }

  async updateApplicationPermissions(
    applicationsToUpdate: ApplicationPermission[]
  ) {
    const update: boolean = await firstValueFrom(
      this.http.post<boolean>(
        `${this.endpoint}/application/updatePermissions`,
        { applicationsToUpdate },
        { headers: this.headers }
      )
    );
    console.log(`updateApplicationPermissions update`, update);
    return update;
  }

  async uploadCurrentProject() {
    try {
      const res = await firstValueFrom(
        this.http.post<any>(
          `${this.endpoint}/project/createOrUpdate`,
          this.currentProject,
          { headers: this.headers }
        )
      );
      return res;
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
      console.error(error);
      throw error;
    }
  }

  async getProjectById(id: number, authorized: boolean = false) {
    const urlSlug = !authorized ? "/project/get/" : "/project/getAuthorized/";
    try {
      const res = await firstValueFrom(
        this.http.get<Projekt>(`${this.endpoint}${urlSlug}${id}`, {
          headers: this.headers,
        })
      );
      return res;
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
      console.error(error);
      throw error;
    }
  }

  async getAllPublishedProjects() {
    try {
      const res = await firstValueFrom(
        this.http.get<Projekt[]>(
          `${this.endpoint}/getAllPublishedProjects${
            this.auth.isLoggedIn ? "ForUser" : ""
          }`,
          { headers: this.headers }
        )
      );
      console.log(`getAllPublished res`, res);
      return res;
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
      console.error(error);
      return [];
    }
  }

  async getAllProjects() {
    try {
      const res = await firstValueFrom(
        this.http.get<Projekt[]>(`${this.endpoint}/project/getAllProjects`, {
          headers: this.headers,
        })
      );
      console.log(`getAllProjects res`, res);
      return res;
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
      console.error(error);
      return [];
    }
  }

  public async uploadFile(file: Media) {
    const url = `${environment.apiUrl}/upload-file`;

    if (!file.fileBaseBlob) {
      throw new Error("No file blob to upload");
    }

    const body = new FormData();
    body.append("file", file.fileBaseBlob);

    return firstValueFrom(this.http.post(url, body));
  }

  async apply(formdata: Object) {
    try {
      this.spinner(true);
      console.log(`formdata`, formdata);
      const res = await firstValueFrom(
        this.http.post<any>(`${this.endpoint}/apply`, formdata, {
          headers: this.headers,
        })
      );
      this.spinner(false);
      console.log(`apply res`, res);
      return res;
    } catch (error) {
      this.spinner(false);
      if (error instanceof HttpErrorResponse) {
        Notify.failure(error?.error || "Etwas ist schief gelaufen.");
        console.log(`error`, error?.error);
      } else {
        Notify.failure("Etwas ist schief gelaufen.");
      }
      console.error(error);
      return false;
    }
  }

  async getProfile(): Promise<Profile> {
    const profile = await firstValueFrom(
      this.http.get<Profile>(`${this.endpoint}/profile`, {
        headers: this.headers,
      })
    );
    if (profile.laufendes_jahr) {
      for (const key of Object.keys(profile.laufendes_jahr)) {
        profile.laufendes_jahr[key].sort((a, b) =>
          dayjs(a.zeitraum_bis).isBefore(dayjs(b.zeitraum_bis)) ? 1 : -1
        );
      }
    }
    return profile;
  }

  /**
   * addGeneratedBeschaeftigung calls the backend to add a generated beschaeftigung to the profile
   * the backend will check if the beschaeftigung already exists and if it does, it will just be add
   * the tage_beschaeftigt integer to the existing beschaeftigung object.
   * @param userId - the id of the user
   * @param beschaeftigung - the beschaeftigung object to add
   * @returns the profile object of the given user with the updated beschaeftigung
   */
  async addGeneratedBeschaeftigung(
    userId: number,
    beschaeftigung: BeschaeftigungLaufendesJahr
  ) {
    try {
      const response = await firstValueFrom(
        this.http.post<any>(
          `${this.endpoint}/profile/addGeneratedBeschaeftigung`,
          { userId, beschaeftigung },
          {
            headers: this.headers,
          }
        )
      );
      console.log(`addGeneratedBeschaeftigung response`, response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getSedCard(): Promise<SED> {
    const sedCard = await firstValueFrom(
      this.http.get<SED>(`${this.endpoint}/sed-card`, { headers: this.headers })
    );
    return sedCard;
  }

  async getMyJobs(): Promise<Application[]> {
    const jobs = await firstValueFrom(
      this.http.get<Application[]>(`${this.endpoint}/get-my-jobs`, {
        headers: this.headers,
      })
    );
    return jobs;
  }

  async getJobById(job_id: number): Promise<Application> {
    const job = await firstValueFrom(
      this.http.get<Application>(`${this.endpoint}/get-job/${job_id}`, {
        headers: this.headers,
      })
    );
    return job;
  }

  async spinner(show: boolean = true) {
    if (show) {
      Loading.circle({
        clickToClose: false,
        svgSize: "50px",
        svgColor: "var(--secondary)",
        // backgroundColor: 'var(--overlay_bg)',
      });
    } else {
      Loading.remove();
    }
  }

  async checkIfEmailExists(email: string) {
    return firstValueFrom(
      this.http.post<number>(
        `${this.endpoint}/users/checkIfEmailExists`,
        { email },
        {
          headers: this.headers,
        }
      )
    );
  }

  async deleteUser(user_id: number) {
    await firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/users/deleteUser`,
        { id: user_id },
        {
          headers: this.headers,
        }
      )
    );
  }

  async updateUser(
    user_id: number | undefined,
    user_data: object,
    profile_data: object
  ) {
    await firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/users/updateUser`,
        { id: user_id, user_data, profile_data },
        {
          headers: this.headers,
        }
      )
    );
  }

  async importUsers(users: User[]) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/users/importUsers`,
        { users },
        {
          headers: this.headers,
        }
      )
    );
  }

  async getUser(user_id: number): Promise<User> {
    const user = await firstValueFrom(
      this.http.post<User>(
        `${this.endpoint}/users/getUser`,
        { id: user_id },
        {
          headers: this.headers,
        }
      )
    );
    return user;
  }

  async getAllUsers(
    // eslint-disable-next-line default-param-last
    onlyCount: boolean = false,
    // eslint-disable-next-line default-param-last
    onlyAdminsAndPMs: boolean = false,
    // eslint-disable-next-line default-param-last
    filters: object = {},
    pagination?: { page: number; perPage: number; pagesTotal: number },
    sort?: sortDirection
  ): Promise<AllUsersResult | number> {
    const body = Object.assign(filters, {
      onlyCount,
      pagination,
      onlyAdminsAndPMs,
      sort,
    });
    console.log("body: ", body);
    const users = await firstValueFrom(
      this.http.post<{ data: User[]; meta: object } | number>(
        `${this.endpoint}/getAllUsers`,
        body,
        {
          headers: this.headers,
        }
      )
    );
    console.log(`users`, users);
    return onlyCount ? <number>users : <AllUsersResult>users;
  }

  async getAllUsersJSONFilterOptions(): Promise<{
    berufserfahrung: string[];
    teilgenommeneEvents: { label: string; id: number }[];
  }> {
    const filterOptions = await firstValueFrom(
      this.http.get<{
        berufserfahrung: string[];
        teilgenommeneEvents: { label: string; id: number }[];
      }>(`${this.endpoint}/getAllUsersJSONFilterOptions`, {
        headers: this.headers,
      })
    );
    console.log(`filterOptions`, filterOptions);
    return filterOptions;
  }

  async getAllPMs(): Promise<User[]> {
    // const body = Object.assign(filters, { onlyCount, pagination });
    const pms = await firstValueFrom(
      this.http.get<User[]>(`${this.endpoint}/users/getAllPMs`, {
        headers: this.headers,
      })
    );
    console.log(`pms`, pms);
    return pms;
  }

  async getAllAcceptedApplicants(
    application_id: number,
    project_id: number
  ): Promise<Application[]> {
    const body = { application_id, project_id };
    console.log("body: ", body);
    const applicants = await firstValueFrom(
      this.http.post<Application[]>(
        `${this.endpoint}/project/getAllAcceptedApplicants`,
        body,
        {
          headers: this.headers,
        }
      )
    );
    console.log(`applicants`, applicants);
    return applicants;
  }

  async getApplicants(
    project_id: number | undefined,
    only_erledigt_verbindlich: boolean = true,
    only_user_roles: UserRole[] = []
  ): Promise<User[]> {
    if (!project_id) {
      throw new Error("No project id");
    }
    const body = {
      project_id,
      only_erledigt_verbindlich,
      only_user_roles,
    };
    const applicants = await firstValueFrom(
      this.http.post<User[]>(`${this.endpoint}/project/getApplicants`, body, {
        headers: this.headers,
      })
    );
    console.log(`applicants`, applicants);
    return applicants;
  }

  async forgotPassword_sendForgotLinkToMail(email: string) {
    const exist: boolean = await firstValueFrom(
      this.http.post<boolean>(
        `${this.endpoint}/forgotPassword_sendForgotLinkToMail`,
        { email },
        { headers: this.headers }
      )
    );
    console.log(`forgotPassword_sendForgotLinkToMail exist`, exist);
    return exist;
  }

  async forgotPassword_checkToken(token: string) {
    const valid: boolean = await firstValueFrom(
      this.http.post<boolean>(
        `${this.endpoint}/forgotPassword_checkToken`,
        { token },
        { headers: this.headers }
      )
    );
    console.log(`forgotPassword_checkToken valid`, valid);
    return valid;
  }

  async forgotPassword_changePassword(token: string, password: string) {
    const ok: boolean = await firstValueFrom(
      this.http.post<boolean>(
        `${this.endpoint}/forgotPassword_changePassword`,
        { token, password },
        { headers: this.headers }
      )
    );
    console.log(`forgotPassword_changePassword ok`, ok);
    return ok;
  }

  async updateUserProfile(
    profile: object | undefined,
    sed: object | undefined,
    user_id: number | undefined = undefined
  ) {
    await firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/updateUserProfile`,
        { profile, sed, user_id },
        {
          headers: this.headers,
        }
      )
    );
  }

  async getTimeSlotsByApplication(applicationId: number) {
    return firstValueFrom(
      this.http.get<TimetrackerSlot[]>(
        `${this.endpoint}/application/get-times/${applicationId}`,
        { headers: this.headers }
      )
    );
  }

  async getTimeSlot(id: number) {
    return firstValueFrom(
      this.http.get<TimetrackerSlot>(
        `${this.endpoint}/application/time/${id}`,
        { headers: this.headers }
      )
    );
  }

  async createTimeSlot(timeSlot: TimetrackerSlot, applicationId: number) {
    const createTimeSlotBody = { ...timeSlot, application_id: applicationId };
    console.log("createTimeSlotBody: ", createTimeSlotBody);
    const endPoint = `${this.endpoint}/application/time`;
    return firstValueFrom(
      this.http.post<TimetrackerSlot>(endPoint, createTimeSlotBody, {
        headers: this.headers,
      })
    );
  }

  async createCheckInTimeSlot(
    timeSlot: TimetrackerSlot,
    applicationId: number,
    projectId: number
  ) {
    const createCheckInTimeSlot = {
      ...timeSlot,
      application_id: applicationId,
      project_id: projectId,
      live_checkin: true,
    };
    console.log("createCheckInTimeSlot: ", createCheckInTimeSlot);
    const endPoint = `${this.endpoint}/application/time/storeLiveCheckIn`;
    return firstValueFrom(
      this.http.post<TimetrackerSlot>(endPoint, createCheckInTimeSlot, {
        headers: this.headers,
      })
    );
  }

  async updateTimeSlot(
    timeSlotId: number,
    updateObject: Partial<TimetrackerSlot>,
    projectId: number
  ) {
    const body = { ...updateObject, project_id: projectId };
    return firstValueFrom(
      this.http.patch<TimetrackerSlot>(
        `${this.endpoint}/application/time/${timeSlotId}`,
        body,
        { headers: this.headers }
      )
    );
  }

  async deleteTimeSlot(timeSlotId: number, applicationId: number) {
    const deleteTimeSlotBody = { application_id: applicationId };
    console.log("deleteTimeSlotBody: ", deleteTimeSlotBody);
    return firstValueFrom(
      this.http.post<TimetrackerSlot>(
        `${this.endpoint}/application/time/delete/${timeSlotId}`,
        deleteTimeSlotBody,
        { headers: this.headers }
      )
    );
  }

  async sendMail(
    mailAddresses: string[],
    messageToSend: string,
    subject: string
  ) {
    return firstValueFrom(
      this.http.post<TimetrackerSlot>(
        `${this.endpoint}/sendMail`,
        { mailAddresses, messageToSend, subject },
        { headers: this.headers }
      )
    );
  }

  public async getDocumentsOfProject(projectId: number) {
    return firstValueFrom(
      this.http.get<Document[]>(
        `${this.endpoint}/project/documents/${projectId}`,
        {
          headers: this.headers,
        }
      )
    );
  }

  public handleApplicationStatus(
    application_id: number,
    action: ApplicationAction,
    application_sed: SED | null = null,
    nachricht_oder_vertrag: string | undefined = undefined,
    vertrag_unterzeichnen_bis: string | undefined = undefined
  ) {
    return firstValueFrom(
      this.http.post<Document>(
        `${this.endpoint}/application/handleApplicationStatus`,
        {
          application_id,
          action,
          application_sed,
          nachricht_oder_vertrag,
          vertrag_unterzeichnen_bis,
        },
        { headers: this.headers }
      )
    );
  }

  public handleRegistrationStatus(payload: {
    user_id: number;
    action: RegistrationAction;
    nachricht: string;
  }) {
    return firstValueFrom(
      this.http.post<Document>(
        `${this.endpoint}/users/handleRegistrationStatus`,
        payload,
        { headers: this.headers }
      )
    );
  }

  public async auswahlAnKundenSenden(body: object) {
    return firstValueFrom(
      this.http.post(`${this.endpoint}/project/auswahlAnKundenSenden`, body, {
        headers: this.headers,
      })
    );
  }

  public async getProjectForAuswahl(
    uuid: string | null,
    preview: boolean = false
  ) {
    return firstValueFrom(
      this.http.post<{
        project: Projekt;
        applicants: User[];
        ansprechperson: User;
      }>(
        `${this.endpoint}/project/getProjectForAuswahl`,
        { uuid, preview },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async entscheidungUebermitteln(uuid: string, applicants: User[]) {
    return firstValueFrom(
      this.http.post(
        `${this.endpoint}/project/entscheidungUebermitteln`,
        { uuid, applicants },
        {
          headers: this.headers,
        }
      )
    );
  }

  public updateAuswahlAnKundenSenden(
    project_id: number,
    auswahl_durch_kunden_zulassen: number
  ) {
    return firstValueFrom(
      this.http.post(
        `${this.endpoint}/project/updateAuswahlAnKundenSenden`,
        { project_id, auswahl_durch_kunden_zulassen },
        {
          headers: this.headers,
        }
      )
    );
  }

  auswahlHinzufuegen(project_id: number) {
    return firstValueFrom(
      this.http.post(
        `${this.endpoint}/project/auswahlHinzufuegen`,
        { project_id },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async getDocumentTemplate(name?: string, application_id?: number) {
    return firstValueFrom(
      this.http.post<DokumentVorlage>(
        `${this.endpoint}/getDocumentTemplate`,
        { name, application_id },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async getAllDocumentTemplates() {
    return firstValueFrom(
      this.http.post<DokumentVorlage[]>(
        `${this.endpoint}/getDocumentTemplate`,
        {},
        {
          headers: this.headers,
        }
      )
    );
  }

  public async saveDocumentTemplates(templates: DokumentVorlage[]) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/admin/saveDocumentTemplates`,
        { templates },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async confirmEmail(token: string) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/confirmEmail`,
        { token },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async getEmploymenContract(applicationId: number) {
    console.log("getEmploymenContract called");
    return firstValueFrom(
      this.http.get<any>(
        `${this.endpoint}/application/employmentContract/${applicationId}`,
        { headers: this.headers }
      )
    );
  }

  public async createEmploymentContract(
    newEmploymentContract: Partial<EmploymentContract>
  ) {
    console.log("createEmploymentContract called");
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/application/employmentContract`,
        newEmploymentContract,
        { headers: this.headers }
      )
    );
  }

  public async updateEmploymentContract(
    employmentContractId: number,
    employmentContractUpdateObject: Partial<EmploymentContract>
  ) {
    console.log("updateEmploymentContract called");
    return firstValueFrom(
      this.http.patch<any>(
        `${this.endpoint}/application/employmentContract/${employmentContractId}`,
        employmentContractUpdateObject,
        { headers: this.headers }
      )
    );
  }

  public async getPDFOfEmploymentContract(employmentContractId: number) {
    console.log("getPDFTest called");
    return firstValueFrom(
      this.http.get<any>(
        `${this.endpoint}/application/employmentContract/pdf/${employmentContractId}`,
        {
          headers: this.headers,
        }
      )
    );
  }

  public async getContactListForApplication(application_id: number) {
    console.log("createEmploymentContract called");
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/application/getContactListForApplication`,
        { application_id },
        { headers: this.headers }
      )
    );
  }

  public async getReviewsForUser(user_id: number) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/getReviewsForUser`,
        { user_id },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async saveReview(payload: {
    id: number | null;
    topteamer_id: number;
    project_id: number | null;
    review_text: string;
  }) {
    const body = Object.assign(payload, {
      reviewer_id: this.auth.currentUser!.id,
    });
    return firstValueFrom(
      this.http.post<any>(`${this.endpoint}/saveReview`, body, {
        headers: this.headers,
      })
    );
  }

  public async saveNote(user_id: number, note: string) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/saveNote`,
        { user_id, note },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async deleteReview(id: number) {
    return firstValueFrom(
      this.http.post<any>(
        `${this.endpoint}/deleteReview`,
        { id },
        {
          headers: this.headers,
        }
      )
    );
  }

  public async setUserActivated(user_id: number, activated: boolean) {
    const body = { user_id, activated };
    return firstValueFrom(
      this.http.post<any>(`${this.endpoint}/setUserActivated`, body, {
        headers: this.headers,
      })
    );
  }

  public async checkIfUserHasFutureBookings(
    user_id: number,
    only_projects_for_requesting_pm_and_project_id?: number
  ) {
    const body = { user_id, only_projects_for_requesting_pm_and_project_id };
    return firstValueFrom(
      this.http.post<{ errors: string[]; applications: Application[] }>(
        `${this.endpoint}/checkIfUserHasFutureBookings`,
        body,
        {
          headers: this.headers,
        }
      )
    );
  }

  public async saveChecklistForProject(body: {
    project_id: number;
    checkliste: any[];
  }) {
    return firstValueFrom(
      this.http.post<string[]>(
        `${this.endpoint}/saveChecklistForProject`,
        body,
        {
          headers: this.headers,
        }
      )
    );
  }

  public async updatePersonalnummer(body: {
    user_id: number;
    personalnummer?: number | null;
  }) {
    return firstValueFrom(
      this.http.post<string[]>(`${this.endpoint}/updatePersonalnummer`, body, {
        headers: this.headers,
      })
    );
  }

  public async postRequest(url: string, body: any) {
    return firstValueFrom(
      this.http.post<any>(`${this.endpoint}${url}`, body, {
        headers: this.headers,
      })
    );
  }

  public async getRequest(url: string, params?: HttpParams): Promise<any> {
    return firstValueFrom(
      this.http.get<string[]>(`${this.endpoint}${url}`, {
        headers: this.headers,
        params,
      })
    );
  }

  /**
   * This is the timetracker CSV export function
   * @param teamTimeSlotDay
   * @param teamTimeSlotDays
   * @param eventName
   * @returns
   */
  async exportTimesCSV(
    teamTimeSlotDay?: TeamTimeSlotDay,
    teamTimeSlotDays: TeamTimeSlotDay[] = [],
    eventName: string = ""
  ) {
    console.log("exportCSVClicked teamTimeSlotDay: ", teamTimeSlotDay);
    let tmpDate = new Date();
    if (
      teamTimeSlotDay &&
      (!teamTimeSlotDay.timeSlots || !teamTimeSlotDay.timeSlots.length)
    ) {
      Notify.warning("Keine Zeiten vorhanden");
      return;
    }
    const single_day =
      teamTimeSlotDay &&
      teamTimeSlotDay.timeSlots &&
      teamTimeSlotDay.timeSlots.length;

    // if (teamTimeSlotDay && teamTimeSlotDay.timeSlots) {
    console.log(`teamTimeSlotDay`, teamTimeSlotDay);
    // go through all timeslots to add a row to the csv
    const header = [
      // "mitarbeiter",
      "vorname",
      "nachname",
      "arbeitstag",
      "intern_beginn_arbeitszeit_formatted",
      "intern_ende_arbeitszeit_formatted",
      "intern_pausenzeit_formatted",
      "intern_arbeitsdauer_formatted",
      "extern_beginn_arbeitszeit_formatted",
      "extern_ende_arbeitszeit_formatted",
      "extern_pausenzeit_formatted",
      "extern_arbeitsdauer_formatted",
    ];
    // if (!single_day) {
    // header.splice(2, 0, "arbeitstag");
    // }
    const jsons: object[] = [];

    let data_to_process = single_day ? [teamTimeSlotDay] : teamTimeSlotDays;
    data_to_process = data_to_process
      .map((dtp) => {
        // eslint-disable-next-line no-param-reassign
        dtp.timeSlots = dtp.timeSlots!.filter((ts) => ts.document_id);
        return dtp;
      })
      .filter(
        (dtp) =>
          dtp.timeSlots &&
          dtp.timeSlots.length &&
          (single_day || dtp.document_ids.length)
      );

    const application_ids_for_zulagen: number[] = [];
    data_to_process.forEach((sTeamTimeSlotDay) => {
      sTeamTimeSlotDay.timeSlots!.forEach((tSlot) => {
        if (tSlot.time_to && (tSlot.time_break || tSlot.time_break === 0)) {
          application_ids_for_zulagen.push(tSlot.application_id!);
          const el_to_push: { [key: string]: any } = {
            vorname: tSlot.vorname,
            nachname: tSlot.nachname,
            intern_beginn_arbeitszeit_formatted: dayjs(tSlot.time_from).format(
              "HH:mm"
            ),
            intern_ende_arbeitszeit_formatted: dayjs(tSlot.time_to).format(
              "HH:mm"
            ),
            intern_pausenzeit_formatted:
              this.getRoundedHoursFromTimestampMillis(tSlot.time_break),
            intern_arbeitsdauer_formatted:
              this.getRoundedHoursFromTimestampMillis(
                tSlot.time_to - tSlot.time_from - tSlot.time_break
              ),
            extern_beginn_arbeitszeit_formatted: dayjs(
              tSlot.fake_time_from || tSlot.time_from
            ).format("HH:mm"),
            extern_ende_arbeitszeit_formatted: dayjs(
              tSlot.fake_time_to || tSlot.time_to
            ).format("HH:mm"),
            extern_pausenzeit_formatted:
              this.getRoundedHoursFromTimestampMillis(
                tSlot.fake_time_break ?? tSlot.time_break
              ),
            extern_arbeitsdauer_formatted:
              this.getRoundedHoursFromTimestampMillis(
                Math.max(
                  (tSlot.fake_time_to ?? tSlot.time_to) -
                    (tSlot.fake_time_from ?? tSlot.time_from),
                  0
                )
              ),
          };
          el_to_push.arbeitstag = sTeamTimeSlotDay.dateLong;
          jsons.push(el_to_push);
        }
      });
      if (single_day) {
        tmpDate = new Date(teamTimeSlotDay.startTimestamp);
      }
    });

    if (jsons.length) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(jsons, { header });

      const formattedEventName = eventName
        .toLowerCase()
        .trim()
        .replace(/ /g, "-");

      const fileName = `timetracker_export_${formattedEventName}_${dayjs(
        tmpDate
      ).format("YYYY-MM-DD_HH-mm")}`;

      ws["!cols"] = header.map((str) => ({ width: str.length }));
      ws["!cols"][0].width = 20;
      ws["!cols"][1].width = 20;
      XLSX.utils.book_append_sheet(wb, ws, "Arbeitszeiten");
      await this.appendZulagenToWorkbook(wb, teamTimeSlotDay, teamTimeSlotDays);

      const applications: Application[] = await this.postRequest(
        `/application/getApplicationsWithUserProfile`,
        {
          application_ids: application_ids_for_zulagen,
        }
      );

      await this.appendStammdatenToWorkbook(wb, applications, false, undefined);

      XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
  }

  getRoundedHoursFromTimestampMillis(timestamp: number): number {
    return Math.round((timestamp / 1000 / 60 / 60) * 100) / 100;
  }

  /**
   * Export for all Projects in a given time and for a given selection - used in Topteamer view.
   * @param body
   * @returns
   */
  async exportTimeslots(body: {
    user_ids?: number[];
    application_ids?: number[];
    time_from?: string;
    time_to?: string;
    document_id_not_null?: boolean;
  }) {
    const applications_res: Application[] = await this.postRequest(
      `/application/time/getApplicationsWithTimesForExport`,
      body
    );
    if (applications_res.length === 0) {
      Notify.warning("Nichts zu exportieren vorhanden.");
      return;
    }
    const applications_grouped_by_project_id: { [key: number]: Application[] } =
      applications_res.reduce(
        (acc: { [key: number]: Application[] }, application: Application) => {
          if (!acc[application.project_id!]) {
            acc[application.project_id!] = [];
          }
          acc[application.project_id!].push(application);
          return acc;
        },
        {}
      );
    const wb = XLSX.utils.book_new();

    let sheetIndex = 0;
    for (const project_id in applications_grouped_by_project_id) {
      const applications = applications_grouped_by_project_id[project_id];
      const sheet_name = `${sheetIndex}-${
        applications[0]!.project?.titel ?? ""
      }`
        .substr(0, 31)
        // replace all not allowed chars / ? * [ ]
        .replace(/[/?*[\]]/g, "-");
      sheetIndex += 1;
      const header = [
        // "mitarbeiter",
        "vorname",
        "nachname",
        "arbeitstag",
        "intern_beginn_arbeitszeit_formatted",
        "intern_ende_arbeitszeit_formatted",
        "intern_pausenzeit_formatted",
        "intern_arbeitsdauer_formatted",
        "extern_beginn_arbeitszeit_formatted",
        "extern_ende_arbeitszeit_formatted",
        "extern_pausenzeit_formatted",
        "extern_arbeitsdauer_formatted",
      ];
      const jsons = applications.reduce((acc: any[], appl: Application) => {
        // const name = `${appl.user?.profile?.vorname} ${appl.user?.profile?.nachname}`;
        acc.push(
          ...(appl.timeslots || [])
            .filter((ts) => {
              return ts.time_to && (ts.time_break || ts.time_break === 0);
            })
            .sort((a, b) => a.time_from - b.time_from)
            .map((ts) => {
              return {
                // mitarbeiter: name,
                vorname: appl.user?.profile?.vorname,
                nachname: appl.user?.profile?.nachname,
                arbeitstag: dayjs(ts.time_from).format("DD.MM.YYYY"),
                // intern
                intern_beginn_arbeitszeit_formatted: dayjs(ts.time_from).format(
                  "HH:mm"
                ),
                intern_ende_arbeitszeit_formatted: dayjs(ts.time_to!).format(
                  "HH:mm"
                ),
                intern_pausenzeit_formatted:
                  this.getRoundedHoursFromTimestampMillis(ts.time_break!),
                intern_arbeitsdauer_formatted:
                  this.getRoundedHoursFromTimestampMillis(
                    ts.time_to! - ts.time_from - ts.time_break!
                  ),
                // extern
                extern_beginn_arbeitszeit_formatted: dayjs(
                  ts.fake_time_from ?? ts.time_from
                ).format("HH:mm"),
                extern_ende_arbeitszeit_formatted: dayjs(
                  ts.fake_time_to ?? ts.time_to!
                ).format("HH:mm"),
                extern_pausenzeit_formatted:
                  this.getRoundedHoursFromTimestampMillis(
                    ts.fake_time_break ?? ts.time_break!
                  ),
                extern_arbeitsdauer_formatted:
                  this.getRoundedHoursFromTimestampMillis(
                    (ts.fake_time_to ?? ts.time_to!) -
                      (ts.fake_time_from ?? ts.time_from)
                  ),
              };
            })
        );
        console.log(`acc`, acc);
        return acc;
      }, []);
      const ws = XLSX.utils.json_to_sheet(jsons, { header });
      ws["!cols"] = header.map((str) => ({ width: str.length }));
      ws["!cols"][0].width = 20;
      ws["!cols"][1].width = 20;
      XLSX.utils.book_append_sheet(wb, ws, sheet_name);
    }

    let time_string = "";
    if (body.time_from) {
      time_string = dayjs(body.time_from).format("YYYY-MM-DD");
    }
    if (body.time_to) {
      time_string += `_${dayjs(body.time_to).format("YYYY-MM-DD")}`;
    }
    if (!time_string) {
      time_string = dayjs().format("YYYY-MM-DD_HH-mm");
    }
    const fileName = `${time_string}_mitarbeiter_export_zeiten`;

    await this.appendZulagenToWorkbook(
      wb,
      undefined,
      [],
      applications_res.filter((a) => a.timeslots?.length)
    );

    await this.appendStammdatenToWorkbook(
      wb,
      applications_res,
      true,
      body.time_to
    );

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  async downloadAllStammdaten(
    body: {
      time_from?: string;
      time_to?: string;
      document_id_not_null?: boolean;
    } = {},
    filter_entries_with_no_eintritt_austritt = false,
    selectedMasterDataFields: { key: string; displayName: string }[] = []
  ) {
    // create
    const users: User[] = await this.postRequest(
      "/users/getDownloadAllStammdatenUsers",
      body
    );
    const wb = XLSX.utils.book_new();
    const applications: Application[] = [];

    for (const user of users) {
      if (user.applications && user.applications.length) {
        console.log(`user.applications 11`, user.applications);
        applications.push(
          ...user.applications.map((el) => {
            // eslint-disable-next-line no-param-reassign
            el.user = user;
            return el;
          })
        );
      } else {
        applications.push({
          user_id: user.id,
          user,
          job_role: "",
        } as Application);
      }
    }

    applications.sort((a: Application, b: Application) => {
      const statusA = a.status ?? "";
      const statusB = b.status ?? "";
      // sort by status (alphabetically)
      if (statusA < statusB) return -1;
      return statusA > statusB ? 1 : 0;
    });

    if (selectedMasterDataFields && selectedMasterDataFields.length > 0) {
      await this.appendSpecificStammdatenToWorkbook(
        wb,
        applications,
        selectedMasterDataFields
      );
    } else {
      await this.appendStammdatenToWorkbook(
        wb,
        applications,
        true,
        body.time_to,
        !!body.time_from &&
          !!body.time_to &&
          filter_entries_with_no_eintritt_austritt
      );
    }

    let time_string = "";
    if (body.time_from) {
      time_string = dayjs(body.time_from).format("YYYY-MM-DD");
    }
    if (body.time_to) {
      time_string += `_${dayjs(body.time_to).format("YYYY-MM-DD")}`;
    }
    if (!time_string) {
      time_string = dayjs().format("YYYY-MM-DD_HH-mm");
    }

    const fileName = `${time_string}_stammdaten_export.xlsx`;

    XLSX.writeFile(wb, fileName);
  }

  /**
   * downloadSelectedStammdatenOfUsers works similar to downloadAllStammdaten
   * but only for the selected users and put only the selected fields as columns
   * and that times and dates are not necessary.
   * @param applicants
   * @param selectedMasterDataFields
   */
  async downloadSelectedStammdatenOfUsers(
    applicants: User[],
    selectedMasterDataFields: { key: string; displayName: string }[],
    optionalFileName?: string
  ) {
    const wb = XLSX.utils.book_new();
    const applications: Application[] = [];

    for (const user of applicants) {
      if (user.applications?.length) {
        console.log(`user.applications 11`, user.applications);
        applications.push(
          ...user.applications.map((el) => {
            // eslint-disable-next-line no-param-reassign
            el.user = user;
            return el;
          })
        );
      } else {
        applications.push({
          user_id: user.id,
          user,
          job_role: "",
        } as Application);
      }
    }

    applications.sort((a: Application, b: Application) => {
      const statusA = a.status ?? "";
      const statusB = b.status ?? "";
      // sort by status (alphabetically)
      if (statusA < statusB) return -1;
      return statusA > statusB ? 1 : 0;
    });

    await this.appendSpecificStammdatenToWorkbook(
      wb,
      applications,
      selectedMasterDataFields
    );

    let fileName = `stammdaten_export_${dayjs().format(
      "YYYY-MM-DD_HH-mm"
    )}.xlsx`;

    if (optionalFileName) {
      fileName = optionalFileName;
    }

    XLSX.writeFile(wb, fileName);
  }

  /**
   * getCorrectFieldValue takes a User and Profile object aswell as a field Object and determines
   * the correct value for the field. It checks if the field is in the user object, in the profile
   * object or if it is a special field like "geschlecht" or "arbeitsbeginn_status".
   * @param field key and displayName of the field
   * @param user A User object which has to have its Profile attached
   * @returns The correct string value for the field
   */
  getCorrectFieldValue(
    field: { key: string; displayName: string },
    application: Application
  ): string {
    const user: User = application.user!;
    const userProfile: Profile = user.profile!;
    if (field.key === "status") {
      return application.status ? application.status : "Kein Status";
    }
    if (field.key in user) {
      return user[field.key as keyof User] as string;
    }
    if (field.key in userProfile) {
      if (field.key === "geschlecht") {
        const { geschlecht } = userProfile;
        let geschlecht_string = geschlecht === "m" ? "Männlich" : "Weiblich";
        geschlecht_string = geschlecht === "d" ? "Divers" : geschlecht_string;
        return geschlecht_string;
      }
      if (field.key === "arbeitsbeginn_status") {
        const beschaeftigung =
          arbeitsBeginnStatusToArbeitsbeginnStatusProfileView(
            userProfile.arbeitsbeginn_status,
            userProfile.arbeitsbeginn_status_additional
          );

        const components: string[] = [];
        for (const curr of beschaeftigung || []) {
          if (curr.name) {
            components.push(curr.name);
          }
          if (curr.gueltig_bis) {
            components.push(`Eingeschrieben bis: ${curr.gueltig_bis}`);
          }
          if (curr.nachweise && curr.nachweise.length) {
            for (const file of curr.nachweise) {
              components.push(`${file.filename}: ${file.url}`);
            }
          }
        }
        return components.join("\n");
      }
      if (
        field.key === "religionszugehoerigkeit" &&
        userProfile.religionszugehoerigkeit?.label
      ) {
        return userProfile.religionszugehoerigkeit.label;
      }
    }
    return userProfile[field.key as keyof Profile] as string;
  }

  /**
   * appendSpecificStammdatenToWorkbook works similar to appendStammdatenToWorkbook
   * but it only created the columns for the selectedMasterDataFields and does not
   * need the date comparisons.
   */
  async appendSpecificStammdatenToWorkbook(
    wb: XLSX.WorkBook,
    applications: Application[],
    selectedMasterDataFields: { key: string; displayName: string }[]
  ) {
    const sheet_name = "Stammdaten";
    const header = [
      ...selectedMasterDataFields.map((field) => field.displayName),
    ];

    // go through all applications to add a row to the csv
    const jsons = applications.map((application) => {
      const row: any = {};

      selectedMasterDataFields.forEach((field) => {
        row[field.displayName] = this.getCorrectFieldValue(field, application);
      });

      return row;
    });

    const ws = XLSX.utils.json_to_sheet(jsons, { header });
    ws["!cols"] = header.map((str) => ({ width: str.length }));
    ws["!cols"].forEach((col, index) => {
      if (ws["!cols"]) ws["!cols"][index].width = 20;
    });
    XLSX.utils.book_append_sheet(wb, ws, sheet_name);
  }

  async appendStammdatenToWorkbook(
    wb: XLSX.WorkBook,
    applications: Application[],
    with_eintritts_austritts_datum: boolean = false,
    austrittsdatum: string | undefined = undefined,
    filter_entries_with_no_eintritt_austritt = false
  ) {
    const sheet_name = "Stammdaten";
    const previous_year_header = `Vorheriges Jahr ${dayjs()
      .subtract(1, "year")
      .format("YYYY")}`;
    const laufendes_jahr_header = `Laufendes Jahr ${dayjs().format("YYYY")}`;

    // group applications by user_id
    const applications_grouped_by_user_id: { [key: number]: Application[] } =
      applications.reduce((acc: any, application: Application) => {
        if (!acc[application.user_id!]) {
          acc[application.user_id!] = [];
        }
        acc[application.user_id!].push(application);
        return acc;
      }, {});

    const header = [
      "Personalnummer",
      // Basics
      "Geschlecht",
      // "Name",
      "Vorname",
      "Nachname",
      "Weitere Vornamen",
      "Geboren am",
      "Staatsangehörigkeit",
      "Geburtsort",
      "Geburtsland",
      "Ausweisnummer",
      // Kontakt
      "E-Mail",
      "Telefon",
      "Straße",
      "Nr.",
      "PLZ",
      "Ort",
      "Wohnland",
      // Bankverbindung
      "IBAN",
      "BIC",
      // Status
      // Evt. nochmal felder checken und ergänzen
      "Beschäftigung",
      "Hauptarbeitgeber",
      "Gewerbeschein",
      "Aufenhaltstitel",
      "Gesundheitszeugnis",
      previous_year_header,
      laufendes_jahr_header,
      // Rechtliches
      "Sozialversicherungsnummer",
      "Steueridentifikationsnummer",
      "Steuerklasse",
      "Krankenkasse",
      "Krankenkassenname",
      "Familienstand",
      "Religion",
      "Kinderfreibetrag",
    ];

    if (with_eintritts_austritts_datum) {
      const index_to_push = header.findIndex(
        (str) => str === laufendes_jahr_header
      );
      header.splice(index_to_push + 1, 0, "Eintrittsdatum");
      header.splice(index_to_push + 2, 0, "Austrittsdatum");
    }

    console.log(
      `applications_grouped_by_user_id`,
      Object.keys(applications_grouped_by_user_id).length
    );

    const jsons = Object.values(applications_grouped_by_user_id).reduce(
      (acc: any[], appls: Application[]) => {
        const eintritt_austritt: { [key: string]: any } = {};
        // IMPORTANT - this is copied to DatevExportsController in the Backend - please do changes also there.
        if (with_eintritts_austritts_datum) {
          eintritt_austritt.Eintrittsdatum = appls.reduce(
            (acc1: number | null, curr1) => {
              const lowest_time_from: number | null = (
                curr1.timeslots || []
              ).reduce((acc2: number | null, curr2) => {
                if (!acc2 || curr2.time_from < acc2) {
                  return curr2.time_from;
                }
                return acc2;
              }, null);

              if (lowest_time_from && !acc1) {
                return lowest_time_from;
              }
              if (lowest_time_from && acc1 && lowest_time_from < acc1) {
                return lowest_time_from;
              }
              return acc1;
            },
            null
          );
          if (eintritt_austritt.Eintrittsdatum) {
            eintritt_austritt.Eintrittsdatum = dayjs(
              eintritt_austritt.Eintrittsdatum
            ).format("DD.MM.YYYY");
          }

          eintritt_austritt.Austrittsdatum = appls.reduce((acc1, curr1) => {
            const highest_time_to = (curr1.timeslots || []).reduce(
              (acc2, curr2) => {
                if (curr2.time_from > acc2) {
                  return curr2.time_from;
                }
                return acc2;
              },
              0
            );
            if (highest_time_to > acc1) {
              return highest_time_to;
            }
            return acc1;
          }, 0);
          if (eintritt_austritt.Austrittsdatum) {
            eintritt_austritt.Austrittsdatum = dayjs(
              eintritt_austritt.Austrittsdatum
            ).format("DD.MM.YYYY");
          } else {
            eintritt_austritt.Austrittsdatum = "";
          }
          const exist_times_after = appls.reduce((acc3, curr3) => {
            if (parseInt(curr3.meta?.exist_times_after || "0", 10) > acc3) {
              return 1;
            }
            return acc3;
          }, 0);
          if (exist_times_after && austrittsdatum) {
            eintritt_austritt.Austrittsdatum = dayjs(
              austrittsdatum,
              "YYYY-MM-DD"
            ).format("DD.MM.YYYY");
          }
        }
        const appl: Application = appls[0];
        // const name = `${appl.user?.profile?.vorname} ${appl.user?.profile?.nachname}`;
        const geschlecht = appl.user?.profile?.geschlecht;
        let geschlecht_string = geschlecht == "m" ? "Männlich" : "Weiblich";
        geschlecht_string = geschlecht == "d" ? "Divers" : geschlecht_string;
        const beschaeftigung =
          arbeitsBeginnStatusToArbeitsbeginnStatusProfileView(
            appl.user?.profile?.arbeitsbeginn_status,
            appl.user?.profile?.arbeitsbeginn_status_additional
          );

        const components: string[] = [];
        for (const curr of beschaeftigung || []) {
          if (curr.name) {
            components.push(curr.name);
          }
          if (curr.gueltig_bis) {
            components.push(`Eingeschrieben bis: ${curr.gueltig_bis}`);
          }
          if (curr.nachweise && curr.nachweise.length) {
            for (const file of curr.nachweise) {
              components.push(`${file.filename}: ${file.url}`);
            }
          }
        }
        const beschaeftigung_string = components.join("\n");
        const res: { [key: string]: any } = {
          Personalnummer: appl.user?.personalnummer,
          Geschlecht: geschlecht_string,
          // Name: name,
          Vorname: appl.user?.profile?.vorname,
          Nachname: appl.user?.profile?.nachname,
          "Weitere Vornamen": appl.user?.profile?.weitere_vornamen,
          "Geboren am": appl.user?.profile?.geburtsdatum,
          Staatsangehörigkeit: (
            appl.user?.profile?.staatsangehoerigkeit_arr || []
          ).join(","),
          Geburtsort: appl.user?.profile?.geburtsstadt,
          Geburtsland: appl.user?.profile?.geburtsland,
          Ausweisnummer: appl.user?.profile?.ausweisnummer,
          "E-Mail": appl.user?.email,
          Telefon: appl.user?.profile?.tel,
          Straße: appl.user?.profile?.strasse,
          "Nr.": appl.user?.profile?.hausnr,
          PLZ: appl.user?.profile?.plz,
          Ort: appl.user?.profile?.ort,
          Wohnland: appl.user?.profile?.wohnland,
          IBAN: appl.user?.profile?.iban,
          BIC: appl.user?.profile?.bic,
          // gegenprüfen
          Beschäftigung: beschaeftigung_string,
          Hauptarbeitgeber: appl.user?.profile?.existiert_hauptarbeitgeber
            ? "Ja"
            : "Nein",
          Aufenthaltstitel: appl.user?.profile?.aufenthaltstitel
            ? "Ja"
            : "Nein",
          Gewerbeschein: appl.user?.profile?.gewerbeschein ? "Ja" : "Nein",
          Gesundheitszeugnis: appl.user?.profile?.gesundheitszeugnis
            ? "Ja"
            : "Nein",

          // gegenprüfen end
          Sozialversicherungsnummer: appl.user?.profile
            ?.sozialversicherungsnummer
            ? appl.user?.profile?.sozialversicherungsnummer.replace(/ /g, "")
            : undefined,
          Steueridentifikationsnummer: appl.user?.profile
            ?.steueridentifikationsnummer
            ? appl.user?.profile?.steueridentifikationsnummer.replace(/ /g, "")
            : undefined,
          Steuerklasse: appl.user?.profile?.steuerklasse,
          Krankenkasse: appl.user?.profile?.krankenkasse_typ,
          Krankenkassenname: appl.user?.profile?.krankenkasse_name,
          Familienstand: appl.user?.profile?.familienstand,
          Religion: appl.user?.profile?.religionszugehoerigkeit?.label,
          Kinderfreibetrag: appl.user?.profile?.kinderfreibetrag
            ? "Ja"
            : "Nein",
        };
        if (appl.user?.profile?.laufendes_jahr?.KurzfristigeBeschaeftigung) {
          const laufendesJahrKfB =
            appl.user?.profile?.laufendes_jahr.KurzfristigeBeschaeftigung.reduce(
              (prev: number, el: BeschaeftigungLaufendesJahr) =>
                isRelevantForKFB(el)
                  ? (el.tage_beschaeftigt || 0) + prev
                  : prev,
              0
            );
          res[laufendes_jahr_header] = `${laufendesJahrKfB} Tage KfB`;
          const previousYearKfB =
            appl.user?.profile?.laufendes_jahr.KurzfristigeBeschaeftigung.reduce(
              (prev: number, el: BeschaeftigungLaufendesJahr) =>
                isRelevantForKFB(el, dayjs().subtract(1, "year").format("YYYY"))
                  ? (el.tage_beschaeftigt || 0) + prev
                  : prev,
              0
            );
          res[previous_year_header] = `${previousYearKfB} Tage KfB`;
        }
        if (
          filter_entries_with_no_eintritt_austritt &&
          !eintritt_austritt.Eintrittsdatum
        ) {
          return acc;
        }
        acc.push(Object.assign(res, eintritt_austritt));
        return acc;
      },
      []
    );

    const ws = XLSX.utils.json_to_sheet(jsons, {
      header,
    });
    setDynamicHeightsAndWidthsOfWorksheet(ws, jsons, header);
    XLSX.utils.book_append_sheet(wb, ws, sheet_name);
  }

  async transformZulagenKeys(zulagen: { [key: string]: any }) {
    const mapping = ZulagenLohnartMappingNeu;
    for (const key in zulagen) {
      if (mapping[key]) {
        // eslint-disable-next-line no-param-reassign
        zulagen[`${key}_${mapping[key]}`] = zulagen[key];
        // eslint-disable-next-line no-param-reassign
        delete zulagen[key];
      }
    }
  }

  async appendZulagenToWorkbook(
    wb: XLSX.WorkBook,
    teamTimeSlotDay?: TeamTimeSlotDay,
    teamTimeSlotDays: TeamTimeSlotDay[] = [],
    applications: Application[] | undefined = undefined
  ) {
    const application_ids = [];
    if (teamTimeSlotDay) {
      application_ids.push(
        ...(teamTimeSlotDay.timeSlots || []).map((tsd) => tsd.application_id)
      );
    } else if (teamTimeSlotDays.length) {
      teamTimeSlotDays.forEach((tsd) => {
        application_ids.push(
          ...(tsd.timeSlots || []).map((ts) => ts.application_id)
        );
      });
    }

    let applications_local = applications;
    if (!applications_local) {
      // eslint-disable-next-line no-param-reassign
      applications_local = await this.postRequest("/application/getZulagen", {
        application_ids: [...new Set(application_ids)],
      });
    }

    applications_local = (applications_local || []).filter((a) => a.zulagen);
    if (!applications_local.length) return;

    applications_local.forEach((a) => {
      this.transformZulagenKeys(a.zulagen!);
    });

    let header = applications_local.reduce((prev, curr) => {
      return prev.concat(Object.keys(curr.zulagen!));
    }, [] as string[]);
    // make header unique
    header = [...new Set(header)];

    // const header = Object.keys(applications_local[0].zulagen!);
    header.sort((a, b) => a.localeCompare(b));
    header.unshift("projekt");
    header.unshift("nachname");
    header.unshift("vorname");
    const json = applications_local.map((a) => ({
      ...a.zulagen,
      vorname: a.user?.profile?.vorname,
      nachname: a.user?.profile?.nachname,
      projekt: a.project?.titel,
    }));
    const ws = XLSX.utils.json_to_sheet(json, { header });
    ws["!cols"] = header.map((str) => ({ width: str.length }));
    ws["!cols"][0].width = 20;
    ws["!cols"][1].width = 20;
    XLSX.utils.book_append_sheet(wb, ws, "Zulagen");
    // console.log(`applications`, applications);
  }

  async hasAdminPriviliges(application_id?: number, project_id?: number) {
    return firstValueFrom(
      this.http.post<boolean>(
        `${this.endpoint}/users/hasAdminPriviliges`,
        { application_id, project_id },
        {
          headers: this.headers,
        }
      )
    );
  }
}

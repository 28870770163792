import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { DatePipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { Router } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import * as Sentry from "@sentry/angular";
import { QRCodeModule } from "angularx-qrcode";
import { DragulaModule } from "ng2-dragula";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { NgxSummernoteModule } from "ngx-summernote";
import { SwiperModule } from "swiper/angular";
import { DataService } from "./data.service";
import { UpdateVersionService } from "./update-version.service";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SearchbarComponent } from "./components/searchbar/searchbar.component";
import { SidebarNavComponent } from "./components/sidebar-nav/sidebar-nav.component";
import { ToggleComponent } from "./components/toggle/toggle.component";
import { LoginComponent } from "./pages/authentication/login/login.component";
import { PasswordResetComponent } from "./pages/authentication/password-reset/password-reset.component";
import { RegisterComponent } from "./pages/authentication/register/register.component";
import { JobsucheComponent } from "./pages/jobsuche/jobsuche.component";

import { DatenComponent } from "./pages/daten/daten.component";
import { MeineJobsComponent } from "./pages/meine-jobs/meine-jobs.component";

import { ButtonComponent } from "./components/button/button.component";
import { AuthInterceptor } from "./shared/authconfig.interceptor";

import { SvgsComponent } from "./components/svgs/svgs.component";
import { ArbeitskraefteComponent } from "./pages/arbeitskraefte/arbeitskraefte.component";
import { EditComponent } from "./pages/meine-projekte/edit/edit.component";
import { InfosComponent } from "./pages/meine-projekte/edit/infos/infos.component";
import { MeineProjekteComponent } from "./pages/meine-projekte/meine-projekte.component";

import { CollapsableComponent } from "./components/collapsable/collapsable.component";
import { DataCollectionWrapperComponent } from "./components/data-collection-wrapper/data-collection-wrapper.component";
import { DobPickerComponent } from "./components/dob-picker/dob-picker.component";
import { FileuploadComponent } from "./components/fileupload/fileupload.component";
import { MehrInfosComponent } from "./components/jobsuche/mehr-infos/mehr-infos.component";
import { ModalComponent } from "./components/modal/modal.component";
import { TableComponent } from "./components/table/table.component";
import { ApplyComponent } from "./pages/apply/apply.component";
import { DatesToDaynamesPipe } from "./pipes/dates-to-daynames.pipe";
import { FormatTwoDatesPipe } from "./pipes/format-two-dates.pipe";

import { BewerbercheckModalComponent } from "./components/bewerbercheck-modal/bewerbercheck-modal.component";
import { BewerbercheckComponent } from "./components/bewerbercheck-modal/bewerbercheck/bewerbercheck.component";
import { InterneInfosComponent } from "./components/bewerbercheck-modal/interne-infos/interne-infos.component";
import { ProfileComponent } from "./components/bewerbercheck-modal/profile/profile.component";
import { SedComponent } from "./components/bewerbercheck-modal/sed/sed.component";
import { EditorComponent } from "./components/editor/editor.component";
import { HeaderWBackbtnLogoComponent } from "./components/header-w-backbtn-logo/header-w-backbtn-logo.component";
import { JobCardComponent } from "./components/job-card/job-card.component";
import { JobUnterlagenComponent } from "./components/job-unterlagen/job-unterlagen.component";
import { KontaktListeComponent } from "./components/kontakt-liste/kontakt-liste.component";
import { OrderingIconsComponent } from "./components/ordering-icons/ordering-icons.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { PhotoGalleryComponent } from "./components/photo-gallery/photo-gallery.component";
import { PlusMinusInputComponent } from "./components/plus-minus-input/plus-minus-input.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { TimeBlockComponent } from "./components/time-block/time-block.component";
import { TimetrackerComponent } from "./components/timetracker/timetracker.component";
import { ZusageAbsageToggleComponent } from "./components/zusage-absage-toggle/zusage-absage-toggle.component";
import { AuswahlComponent } from "./pages/auswahl/auswahl.component";
import { ConfirmEmailComponent } from "./pages/confirm-email/confirm-email.component";
import { JobDetailComponent } from "./pages/job-detail/job-detail.component";
import { AblaufComponent } from "./pages/meine-projekte/edit/ablauf/ablauf.component";
import { PersonalComponent } from "./pages/meine-projekte/edit/personal/personal.component";
import { ProjekteinstellungenModalComponent } from "./pages/meine-projekte/edit/projekteinstellungen-modal/projekteinstellungen-modal.component";
import { ZeiterfassungComponent } from "./pages/meine-projekte/edit/zeiterfassung/zeiterfassung/zeiterfassung.component";
import { VorlagenComponent } from "./pages/vorlagen/vorlagen.component";
import { VerticalImageSliderComponent } from "./vertical-image-slider/vertical-image-slider.component";

import { ProjektdatenComponent } from "./components/bewerbercheck-modal/projektdaten/projektdaten.component";
import { VertragComponent } from "./components/bewerbercheck-modal/projektdaten/vertrag/vertrag.component";
import { ZulagenComponent } from "./components/bewerbercheck-modal/projektdaten/zulagen/zulagen.component";
import { ProtokollComponent } from "./components/bewerbercheck-modal/protokoll/protokoll.component";
import { JobRolesModalComponent } from "./components/job-roles-modal/job-roles-modal.component";
import { ImportComponent } from "./pages/import/import.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";

import { BewerberNotizComponent } from "./components/bewerbercheck-modal/bewerber-notiz/bewerber-notiz.component";
import { ArbeitsbeginnStatusComponent } from "./components/bewerbercheck-modal/profile/arbeitsbeginn-status/arbeitsbeginn-status.component";
import { MasterDataExportConfiguratorComponent } from "./components/master-data-export-configurator/master-data-export-configurator.component";
import { QrScannerElementComponent } from "./components/qr-scanner-element/qr-scanner-element.component";
import { SendMailToAuswahlModalComponent } from "./components/send-mail-to-auswahl-modal/send-mail-to-auswahl-modal.component";
import { SignaturePadModule } from "./components/signature-pad/angular2-signaturepad.module";
import { TimepickerComponent } from "./components/timepicker/timepicker.component";
import { CoreModule } from "./core.module";
import { ExporteComponent } from "./pages/exporte/exporte.component";
import { CopyPersonalToOtherProjectModalComponent } from "./pages/meine-projekte/edit/personal/copy-personal-to-other-project-modal/copy-personal-to-other-project-modal.component";

import { dayjs } from "./shared/date-util";

registerLocaleData(localeDe, "de-DE", localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    ToggleComponent,
    SidebarNavComponent,
    JobsucheComponent,
    SearchbarComponent,
    LoginComponent,
    RegisterComponent,
    PasswordResetComponent,
    MeineJobsComponent,
    DatenComponent,
    ButtonComponent,
    SvgsComponent,
    MeineProjekteComponent,
    ArbeitskraefteComponent,
    EditComponent,
    InfosComponent,
    TableComponent,
    FormatTwoDatesPipe,
    MehrInfosComponent,
    DatesToDaynamesPipe,
    ApplyComponent,
    DataCollectionWrapperComponent,
    CollapsableComponent,
    FileuploadComponent,
    ModalComponent,
    DobPickerComponent,
    JobCardComponent,
    JobDetailComponent,
    HeaderWBackbtnLogoComponent,
    VerticalImageSliderComponent,
    TimetrackerComponent,
    PlusMinusInputComponent,
    TimeBlockComponent,
    ProjekteinstellungenModalComponent,
    PersonalComponent,
    ProgressBarComponent,
    PaginationComponent,

    // subcomponents BewerbercheckModalComponent
    // BewerbercheckModalBewerbercheckComponent,
    // BewerbercheckModalProfileComponent,
    PhotoGalleryComponent,
    BewerbercheckModalComponent,
    BewerbercheckComponent,
    BewerberNotizComponent,
    ProfileComponent,
    SedComponent,
    ZeiterfassungComponent,
    AuswahlComponent,
    ZusageAbsageToggleComponent,
    VorlagenComponent,
    JobUnterlagenComponent,
    ConfirmEmailComponent,
    KontaktListeComponent,
    EditorComponent,
    OrderingIconsComponent,
    InterneInfosComponent,
    AblaufComponent,
    SafeHtmlPipe,
    ImportComponent,
    ProjektdatenComponent,
    VertragComponent,
    ZulagenComponent,
    ProtokollComponent,
    JobRolesModalComponent,
    SendMailToAuswahlModalComponent,
    ArbeitsbeginnStatusComponent,
    CopyPersonalToOtherProjectModalComponent,
    ExporteComponent,
    TimepickerComponent,
    QrScannerElementComponent,
    MasterDataExportConfiguratorComponent,
  ],
  imports: [
    ZXingScannerModule,
    NgSelectModule,
    SwiperModule,
    NgxSummernoteModule,
    DragulaModule.forRoot(),
    CoreModule,
    QRCodeModule,
    SignaturePadModule,

    // MatSelectModule,
    // NgxMatSelectSearchModule,

    // MatSelectModule,
    // NgxMatSelectSearchModule,
    // QuillModule.forRoot({
    //   placeholder: "",
    //   modules: {
    //     toolbar: [
    //       [
    //         "bold",
    //         "italic",
    //         "link",
    //         "underline",
    //         { align: ["", "center", "right", "justify"] },
    //         { header: [1, 2, 0] },
    //         { list: "bullet" },
    //       ],
    //     ],
    //   },
    // }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDaterangepickerMd.forRoot({
      format: "DD.MM.YYYY", // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: "DD.MM.YYYY", // default is format value
      direction: "ltr", // could be rtl
      weekLabel: "W",
      separator: " bis ", // default is ' - '
      cancelLabel: "Abbrechen", // detault is 'Cancel'
      applyLabel: "Auswählen", // detault is 'Apply'
      clearLabel: "Zurücksetzen", // detault is 'Clear'
      customRangeLabel: "Benutzerdefiniert",
      daysOfWeek: dayjs.weekdaysMin(),
      monthNames: dayjs.monthsShort(),
      firstDay: 1, // first day is monday
      // showClearButton: true,
      // showCancel: true,
    }),
  ],
  providers: [
    DataService,
    UpdateVersionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "de-DE" },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
